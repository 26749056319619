import { createBrowserHistory } from 'history'
import React from 'react'
import { CONSTANTS } from 'helpers/const'
export const history = createBrowserHistory()

export const routes = {
  index: {
    id: 'index',
    name: 'Home',
    description: 'Home',
    path: '/',
    path_string: () => {
      return `/`
    },
    exact: true,
    isPrivate: true,
    companyRequired: true,
    // leftSidebar: CONSTANTS.ROUTE_SIDEBAR.FEED,
    // rightSidebar: CONSTANTS.ROUTE_SIDEBAR.FEED,
    header: false,
    component: React.lazy(() => import('../pages/Feedpage/Feedpage')),
  },
  search: {
    id: 'search',
    name: 'Search',
    description: 'Home',
    path: '/search',
    path_string: () => {
      return `/search`
    },
    exact: true,
    searchPage: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.GLOBAL_SEARCH,
    rightSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/GlobalSearch/GlobalSearch')),
  },
  feedDetail: {
    path: '/post-detail/:id',
    component: React.lazy(() => import('../pages/PostDetail/PostDetail')),
    path_string: (id) => `/post-detail/${id}`,
    id: 'postdetail',
    name: 'Post Detail',
    exact: false,
    companyRequired: true,
    rightSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
  },
  signup: {
    id: 'signup',
    name: 'Sign Up',
    path: '/signup',
    path_string: () => {
      return `/signup`
    },
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    isAuth: true,
    component: React.lazy(() => import('../pages/Signup/Signup')),
  },
  // communitySignup: {
  //   id: 'communitySignup',
  //   name: 'Community Sign Up',
  //   path: '/signup/community/:id',
  //   path_string: (id) => {
  //     return `/signup/community/${id}`
  //   },
  //   exact: true,
  //   isPrivate: false,
  //   leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
  //   header: false,
  //   component: React.lazy(() =>
  //     import('../pages/CommunitySignup/CommunitySignup')
  //   ),
  // },
  login: {
    id: 'login',
    name: 'Log In',
    path: '/login',
    path_string: () => {
      return `/login`
    },
    exact: true,
    isAuth: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() => import('../pages/Login/Login')),
  },
  // communityLogin: {
  //   id: 'login',
  //   name: 'Community Log In',
  //   path: '/login/community/:id',
  //   path_string: (id) => {
  //     return `/login/community/${id}`
  //   },
  //   exact: true,
  //   isPrivate: false,
  //   leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
  //   header: false,
  //   component: React.lazy(() =>
  //     import('../pages/CommunityLogin/CommunityLogin')
  //   ),
  // },
  forgotpassword: {
    id: 'forgotpassword',
    name: 'Forgot Password',
    path: '/forgotpassword',
    path_string: () => {
      return `/forgotpassword`
    },
    exact: true,
    isPrivate: false,
    isAuth: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    companyRequired: false,
    component: React.lazy(() =>
      import('../pages/Forgotpassword/Forgotpassword')
    ),
  },
  verifyemailaddress: {
    id: 'verifyemailaddress',
    name: 'Verify email address',
    path: '/verifyemailaddress',
    path_string: () => {
      return `/verifyemailaddress`
    },
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() =>
      import('../pages/Verifyemailaddress/Verifyemailaddress')
    ),
  },
  businessoragency: {
    id: 'businessoragency',
    name: 'Are you a Business or Agency',
    path: '/business-or-agency',
    path_string: () => {
      return `/business-or-agency`
    },
    exact: true,
    isPrivate: true,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() =>
      import('../pages/BusinessOrAgency/BusinessOrAgency')
    ),
  },
  findcompany: {
    id: 'findcompany',
    name: 'Find Company/Organization',
    path: '/find-company',
    path_string: (param) => {
      return `/find-company?type=${param}`
    },
    exact: true,
    isPrivate: true,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() => import('../pages/FindCompany/FindCompany')),
  },
  inviteMembers: {
    id: 'inviteMembers',
    name: 'inviteMembers',
    path: '/invite-members',
    path_string: () => {
      return `/invite-members`
    },
    companyRequired: true,
    exact: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/InviteMembers/InviteMembers')),
    // isBlockedForNotMember: true,
  },
  verifyExistingVendorInvite: {
    id: 'verifyExistingVendorInvite',
    name: 'verifyExistingVendorInvite',
    path: '/verify-existing-vendor-invite',
    path_string: () => {
      return `/verify-existing-vendor-invite`
    },
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() =>
      import('../pages/VerifyVendorInvitePage/VerifyVendorInvitePage')
    ),
    // isBlockedForNotMember: true,
  },
  resetpassword: {
    id: 'resetpassword',
    name: 'Reset Password',
    path: '/resetpassword',
    path_string: () => {
      return `/resetpassword`
    },
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() => import('../pages/Resetpassword/Resetpassword')),
  },
  resetpasswordverification: {
    id: 'resetpasswordverification',
    name: 'Reset password verification',
    path: '/resetpasswordverification',
    path_string: () => {
      return `/resetpasswordverification`
    },
    exact: true,
    isPrivate: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    companyRequired: false,
    component: React.lazy(() =>
      import('../pages/Resetpasswordverification/Resetpasswordverification')
    ),
  },
  opportunitieDetailV2: {
    id: 'opportunitiesV2Detail',
    name: 'OpportunitiesV2Detail',
    description: 'Opportunities V2 Detail Page',
    path: '/opportunities/detail/:id',
    path_string: (id, type = 'federal') => {
      return `/opportunities/detail/${id}?type=${type}`
    },
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/OpportunityDetailPageV2/OpportunityDetailPageV2')
    ),
    isBlockedForNotMember: true,
  },
  opportunitieDetailV2Public: {
    id: 'opportunitiesV2DetailPublic',
    name: 'OpportunitiesV2DetailPublic',
    description: 'Opportunities V2 Detail Public Page',
    path: '/public/opportunities/detail/v2/:id',
    path_string: (id) => {
      return `/public/opportunities/detail/v2/${id}`
    },
    isAuth: true,
    exact: true,
    isPrivate: false,
    companyRequired: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() =>
      import('../pages/OpportunityDetailPageV2/OpportunityDetailPageV2')
    ),
  },
  opportunities: {
    id: 'opportunities',
    name: 'Opportunities',
    description: 'Opportunities',
    path: '/opportunities',
    path_string: (id) => {
      return `/opportunities/detail/${id}`
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/Opportunitypage/Opportunitypage')
    ),
    isBlockedForNotMember: true,
  },
  awards: {
    id: 'awards',
    name: 'Awards',
    description: 'Awards',
    path: '/awards',
    path_string: () => {
      return `/awards`
    },
    companyRequired: true,
    exact: false,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/AwardsPage/AwardsPage')),
    isBlockedForNotMember: true,
  },
  // mynetwork: {
  //   id: 'mynetwork',
  //   name: 'My Network',
  //   description: 'My Network',
  //   path: '/my-network',
  //   path_string: () => {
  //     return `/my-network`
  //   },
  //   exact: true,
  //   isPrivate: true,
  //   companyRequired: true,
  //   leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
  //   header: true,
  //   component: React.lazy(() => import('../pages/MyNetworkPage/MyNetworkPage')),
  //   isBlockedForNotMember: true,
  // },
  companydetail: {
    id: 'companydetail',
    name: 'Company Detail',
    path: '/company/:companyId',
    path_string: (companyId) => {
      return `/company/${companyId}`
    },
    isDynamic: true,
    companyRequired: false,
    exact: true,
    isPrivate: false,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/CompanyDetailPage/CompanyDetailPage')
    ),
  },
  saved: {
    id: 'saved',
    name: 'Saved',
    path: '/saved/:type',
    path_string: (type = 'award') => {
      return `/saved/${type}`
    },
    isDynamic: true,
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Saved/Saved')),
  },
  updatecompanywizard: {
    id: 'updatecompanywizard',
    name: 'Update Company Wizard',
    path: '/update-company-wizard',
    component: React.lazy(() =>
      import('../pages/UpdateCompanyWizard/UpdateCompanyWizard')
    ),
    isDynamic: true,
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
  },
  trackedopportunities: {
    id: 'trackedopportunities',
    name: 'Tracked',
    path: '/tracked-opportunities/:type',
    isDynamic: true,
    path_string: (type = 'federal') => {
      return `/tracked-opportunities/${type}`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/TrackedOpportunity/TrackedOpportunity')
    ),
    isBlockedForNotMember: false,
  },
  messages: {
    id: 'messages',
    name: 'Messages',
    path: '/messages',
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/MyMessagesPage/MyMessagesPage')
    ),
  },
  // statelevelopportunities: {
  //   id: 'statelevelopportunities',
  //   name: 'State Level Opportunities',
  //   path: '/state-level-opportunities',
  //   path_string: (id) => {
  //     return `/state-level-opportunities/detail/${id}`
  //   },
  //   exact: false,
  //   companyRequired: true,
  //   isPrivate: true,
  //   leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
  //   header: true,
  //   component: React.lazy(() =>
  //     import('../pages/StateLevelOpportunity/StateLevelOpportunity')
  //   ),
  // },
  myprofilepage: {
    id: 'myprofilepage',
    name: 'Myprofilepage',
    path: '/myprofile',
    path_string: (key) => {
      return `/myprofile/${key}`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Myprofilepage/Myprofilepage')),
    isBlockedForNotMember: false,
  },
  userProfile: {
    path: '/user-profile/:id',
    component: React.lazy(() =>
      import('../pages/UserProfilePage/UserProfilePage')
    ),
    path_string: (id) => `/user-profile/${id}`,
    exact: true,
    secure: true,
    companyRequired: true,
    sidebarmenu: true,
    header: true,
    id: 'userprofile',
    name: 'User Profile',
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    isBlockedForNotMember: false,
  },
  myteampage: {
    id: 'myteampage',
    name: 'myteampage',
    path: '/myprofile/team',
    path_string: () => {
      return `/myprofile/team`
    },
    exact: true,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Myprofilepage/Myprofilepage')),
    isBlockedForNotMember: true,
  },
  mycompanypage: {
    id: 'mycompanypage',
    name: 'mycompanypage',
    path: '/myprofile/company',
    path_string: () => {
      return `/myprofile/company`
    },
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Myprofilepage/Myprofilepage')),
    isBlockedForNotMember: true,
  },
  bidboard: {
    id: 'bidboard',
    name: 'Bid Board',
    path: '/bidboard',
    path_string: () => {
      return `/bidboard`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Bidboardpage/Bidboardpage')),
    isBlockedForNotMember: true,
  },
  bidboarddetailpage: {
    id: 'bidboarddetailpage',
    name: 'bidboarddetailpage',
    path: '/bid/:id',
    path_string: (id) => {
      return `/bid/${id}`
    },
    companyRequired: true,
    exact: false,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/Bidboarddetailpage/Bidboarddetailpage')
    ),
    isBlockedForNotMember: true,
  },
  archivedpage: {
    id: 'archivedpage',
    name: 'Archived',
    path: '/archived',
    path_string: (id) => {
      return `/archived`
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Archivedpage/Archivedpage')),
    isBlockedForNotMember: true,
  },
  teams: {
    id: 'teams',
    name: 'Teaming Portal',
    path: '/teams',
    path_string: (id) => {
      return `/teams`
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Teamingportal/Teamingportal')),
    isBlockedForNotMember: true,
  },
  teamdetailpage: {
    id: 'teamdetailpage',
    name: 'Team Detail',
    path: '/team/:id',
    path_string: (id) => {
      return `/team/${id}`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/Teamdetailpage/Teamdetailpage')
    ),
    isBlockedForNotMember: true,
  },
  pricing: {
    id: 'pricing',
    name: 'Pricing',
    path: '/pricing',
    path_string: () => {
      return `/pricing`
    },
    exact: true,
    isPrivate: true,
    companyRequired: true,
    isSubscriptionRoute: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() => import('../pages/Pricing/Pricing')),
    // isBlockedForNotMember: true,
  },
  accountdeactivatedpage: {
    id: 'accountdeactivatedpage',
    name: 'Account Deactivated',
    path: '/account-deactivated',
    path_string: () => {
      return `/account-deactivated`
    },
    exact: true,
    isSubscriptionRoute: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import(
        '../components/Subscription/AccountDeactivatedPage/AccountDeactivatedPage'
      )
    ),
  },
  accountlimitexceeded: {
    id: 'accountlimitexceeded',
    name: 'Account Limit Exceeded',
    path: '/account-limit-exceeded',
    path_string: () => {
      return `/account-limit-exceeded`
    },
    isSubscriptionRoute: true,
    exact: true,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import(
        '../components/Subscription/AccountLimitExceededpage/AccountLimitExceededpage'
      )
    ),
  },
  subscriptionexpired: {
    id: 'subscriptionexpired',
    name: 'Subscription Expired',
    path: '/subscription-expired',
    path_string: () => {
      return `/subscription-expired`
    },
    isSubscriptionRoute: true,
    exact: true,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import(
        '../components/Subscription/SubscriptionExpiredpage/SubscriptionExpiredpage'
      )
    ),
  },
  subscriptionpending: {
    id: 'subscriptionpending',
    name: 'Subscription Pending',
    path: '/subscription-pending',
    path_string: () => {
      return `/subscription-pending`
    },
    isSubscriptionRoute: true,
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import(
        '../components/Subscription/SubscriptionPendingpage/SubscriptionPendingpage'
      )
    ),
  },
  companyplanchanged: {
    id: 'companyplanchanged',
    name: 'Company Plan Changed',
    path: '/awaiting-payment',
    path_string: () => {
      return `/awaiting-payment`
    },
    companyRequired: true,
    isSubscriptionRoute: true,
    exact: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../components/Subscription/CompanyPlanChanged/CompanyPlanChanged')
    ),
  },
  reviewsubscription: {
    id: 'reviewsubscription',
    name: 'Review Subscription',
    path: '/review-subscription',
    path_string: () => {
      return `/review-subscription`
    },
    isSubscriptionRoute: true,
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: false,
    component: React.lazy(() =>
      import('../pages/ReviewSubscription/ReviewSubscription')
    ),
    isBlockedForNotMember: true,
  },
  resources: {
    id: 'resources',
    name: 'Resources',
    path: '/resources',
    path_string: () => {
      return `/resources`
    },
    exact: true,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Resourcespage/Resourcespage')),
    isBlockedForNotMember: true,
  },
  contractordirectory: {
    id: 'contractordirectory',
    name: 'Directory',
    path: '/directory',
    path_string: (id) => {
      return `/directory/detail/${id}`
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/Contractorpage/Contractorpage')
    ),
    isBlockedForNotMember: false,
  },
  crm: {
    id: 'crm',
    name: 'CRM',
    path: '/crm',
    path_string: (view = '') => {
      return `/crm/${view}`
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Crm/Crm')),
    isBlockedForNotMember: true,
  },
  dashboard: {
    id: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    path_string: () => {
      return '/dashboard'
    },
    exact: false,
    isPrivate: true,
    companyRequired: true,
    header: true,
    component: React.lazy(() => import('../pages/Dashboard/Dashboard')),
    isBlockedForNotMember: true,
  },
  crmdetailpage: {
    id: 'crmdetailpage',
    name: 'CRM Detail',
    path: '/contact/:id',
    path_string: (id) => {
      return `/contact/${id}`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() => import('../pages/Crmdetailpage/Crmdetailpage')),
    isBlockedForNotMember: true,
  },
  // communityPage: {
  //   id: 'communityPage',
  //   name: 'Communities',
  //   path: '/communities',
  //   path_string: (id) => {
  //     return `/communities`
  //   },
  //   exact: false,
  //   isPrivate: true,
  //   leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
  //   header: true,
  //   component: React.lazy(() => import('../pages/Communities/Communities')),
  //   isBlockedForNotMember: true,
  // },
  communityDetailPage: {
    id: 'communityDetailPage',
    name: 'CommunityDetail',
    path: '/community-detail/:id',
    path_string: (id) => {
      return `/community-detail/${id}`
    },
    companyRequired: true,
    exact: false,
    isPrivate: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    header: true,
    component: React.lazy(() =>
      import('../pages/Communitydetailpage/Communitydetailpage')
    ),
    isBlockedForNotMember: true,
  },
  eventDetailsPage: {
    id: 'eventDetailsPage',
    name: 'Event Details',
    path: '/event-details/:id',
    path_string: (id) => {
      return `/event-details/${id}`
    },
    exact: false,
    companyRequired: true,
    isPrivate: true,
    header: true,
    isBlockedForNotMember: true,
    leftSidebar: CONSTANTS.ROUTE_SIDEBAR.DEFAULT,
    component: React.lazy(() =>
      import('../pages/EventDetailsPage/EventDetailsPage')
    ),
  },
}
