import React from 'react'
import { Layout } from 'antd'
// import initHelpHero from 'helphero'
import LeftSidebar from 'components/Leftsidebar/Leftsidebar'
import Rightsidebar from 'components/Rightsidebar/Rightsidebar'

import Topheader from 'components/Topheader/Topheader'
import Footer from 'components/Footer/Footer'
import { firebaseAuth } from 'helpers/firebase'
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import classnames from 'classnames'
import { Redirect, Route, Router, Switch } from 'react-router'
import { logout, validateUser, triggerNoAccessModal } from 'store/action/auth'
import { getCompanySubscription } from 'store/action/company'
import { history, routes } from './config'
import PrivateRoute from './privateRoute'
import PublicRoute from './publicRoute'
import Modalbiddecisionmatrix from 'components/Modalbiddecisionmatrix/Modalbiddecisionmatrix'
import Modalblockedfornotmember from 'components/Modalblockedfornotmember/Modalblockedfornotmember'
import Loader from 'components/Loader/Loader'
import ModalNoAccess from 'components/ModalNoAccess/ModalNoAccess'
import ModalSubscriptionPrompt from 'components/ModalSubscriptionPrompt/ModalSubscriptionPrompt'
import ChromeBar from 'components/ChromeExtension/ChromeBar/ChromeBar'
// import ChromeBar from 'components/ChromeExtension/ChromeBar/ChromeBar'
// import ChromeModal from 'components/ChromeExtension/ChromeModal/ChromeModal'

// const helpHero = initHelpHero(process.env.REACT_APP_HELPHERO_APP_ID)

const withLayout = (
  Component,
  { header, leftSidebar, isCollapse, rightSidebar, searchPage }
) => {
  return (
    <div className={`app ${!isCollapse ? 'collapsed' : ''}`}>
      <Layout className='mainLayout'>
        <Modalblockedfornotmember />
        <Modalbiddecisionmatrix />

        {header && (
          <>
            <Layout.Header theme='light'>
              <Topheader searchPage={searchPage} />
            </Layout.Header>
          </>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {/* <ChromeBar /> */}

          <Layout className='site-layout'>
            {!!leftSidebar && <LeftSidebar type={leftSidebar} />}
            <Layout.Content
              className={classnames({
                'main-site-content site-layout-background': true,
                'with-left-sidebar': !!leftSidebar,
                'with-right-sidebar': !!rightSidebar,
              })}
            >
              <Component />
            </Layout.Content>
            {!!rightSidebar && <Rightsidebar type={rightSidebar} />}
            {/* <Footer /> */}
          </Layout>
        </div>
      </Layout>
      <ModalSubscriptionPrompt />
    </div>
  )
}

const StaticRouter = () => (
  <Router history={history}>
    <Switch>
      {Object.keys(routes).map((key) => {
        const value = routes[key]
        const {
          component: Component,
          header,
          leftSidebar,
          isPrivate,
          isStatic,
          ...all
        } = value

        return (
          <Route
            {...all}
            render={() => withLayout(Component, { header, leftSidebar })}
          />
        )
      })}
    </Switch>
  </Router>
)

const AppRouter = ({
  validateUser,
  getCompanySubscription,
  user,
  logout,
  isCollapse,
  triggerNoAccessModal,
  switchCompanyLoading,
}) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const validateSession = async () => {
      try {
        await validateUser()
      } catch (error) {
        // console.log(error, 'error')
        // console.log(history.location.search, 'search')
        // history.push({
        //   pathname: routes.login.path,
        //   search: history.location.search,
        // })
      } finally {
        setLoading(false)
      }
    }

    validateSession()
  }, [])

  return loading ? (
    <Loader
      delay={500}
      className='center'
      size='large'
      wrapperClass='fullpage-loader'
    />
  ) : (
    <Router history={history}>
      <ModalNoAccess />
      <Switch>
        {Object.keys(routes)
          // .filter((key) => {
          //   const value = routes[key]
          //   return !(value.isBlockedForNotMember === false && !user.companyId)
          // })
          .map((key) => {
            const value = routes[key]
            const {
              component: Component,
              header,
              rightSidebar,
              leftSidebar,
              isPrivate,
              isStatic,
              searchPage,
              ...all
            } = value

            if (isStatic) {
              return (
                <Route
                  {...all}
                  render={() =>
                    withLayout(Component, {
                      header,
                      leftSidebar,
                      isCollapse,
                      rightSidebar,
                      searchPage,
                    })
                  }
                />
              )
            } else {
              const SelectedRoute = isPrivate ? PrivateRoute : PublicRoute
              return (
                <SelectedRoute
                  {...all}
                  key={key}
                  render={() =>
                    withLayout(Component, {
                      header,
                      leftSidebar,
                      isCollapse,
                      rightSidebar,
                      searchPage,
                    })
                  }
                />
              )
            }
          })}
        <Route path='*'>
          <Redirect to={routes.index.path} />
        </Route>
      </Switch>
    </Router>
  )
}

const mapStateToProps = ({ auth, config }) => ({
  user: auth.user,
  loading: auth.loading.user,
  switchCompanyLoading: auth.loading.switchCompany,
  isCollapse: config.isColllapse,
})
// export default StaticRouter

export default connect(mapStateToProps, {
  validateUser,
  logout,
  getCompanySubscription,
  triggerNoAccessModal,
})(AppRouter)
